import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import Summary from "./Summary";
import Emitter from "../../services/Emitter";
import Lookup from "./Lookup";
import Volume from "./Volume";
import Trends from "./Trends";
import ProductDiscovery from "./ProductDiscovery";
import CrawlingEffort from "./CrawlingEffort";
import Merchants from "./Merchants";
import BrandService from "../../services/BrandService";
import Auth from "../../services/AuthService";
import Profile from "./Profile";
import MyCarousel from "../../components/MyCarousel";
import Users from "./Users";
import Products from "./Products";
import Restrictions from "./Restrictions";
import Costs from "./Costs";

class Index extends React.Component {
    constructor(props) {
        super(props);
        let brandId = this.props.match.params.id * 1;
        this.state = {
            brand_name: "...",
            brandId
        };
        this.firstLoad = true;
        BrandService.breadcrumb(brandId).then((name) => {
            this.setState({ brand_name: name });
        }).catch(() => {
            this.setState({ brand_name: '?' });
        })
        this.showCosts = Auth.hasAccessToCosts();
    }

    showPage(tab) {
        let key, event;
        if (tab === "#profile") {
            key = 1;
            event = "BRAND_PROFILE";
        } else if (tab === "#users") {
            key = 2;
            event = "BRAND_USERS";
        } else if (tab === "#products") {
            key = 3;
            event = "BRAND_PRODUCTS";
        } else if (tab === "#volume") {
            key = 4;
            event = "BRAND_VOLUME";
        } else if (tab === "#lookup") {
            key = 5;
            event = "BRAND_LOOKUP";
        } else if (tab === "#restrictions") {
            key = 6;
            event = "BRAND_RESTRICTIONS";
        } else if (tab === "#trends") {
            key = 7;
            event = "BRAND_TRENDS";
        } else if (tab === "#merchants") {
            key = 8;
            event = "BRAND_MERCHANTS";
        } else if (tab === "#crawlingeffort") {
            key = 9;
            event = "BRAND_CRAWLING_EFFORT";
        } else if (tab === "#costs") {
            key = 10;
            event = "BRAND_COSTS";
        } else if (tab === "#discovery") {
            key = 11;
            event = "BRAND_DISCOVERY";
        } else {
            key = 0;
            event = "BRAND_SUMMARY";
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
                Emitter.emit(event);
            }, 250);
        } else {
            Emitter.emit(event);
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#summary" className="standardGrid">
                    <Summary brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#profile" className="standardGrid">
                    <Profile brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#users" className="standardGrid">
                    <Users brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#products" className="standardGrid">
                    <Products brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#volume" className="standardGrid">
                    <Volume brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#lookup" className="standardGrid">
                    <Lookup brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#restrictions" className="standardGrid">
                    <Restrictions brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#trends" className="standardGrid">
                    <Trends brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#merchants" className="standardGrid">
                    <Merchants brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#crawlingeffort" className="standardGrid">
                    <CrawlingEffort brandId={this.state.brandId} />
                </Carousel.Item>
                <Carousel.Item key="#costs" className="standardGrid">
                    {this.showCosts && <Costs brandId={this.state.brandId} />}
                </Carousel.Item>
                <Carousel.Item key="#discovery" className="standardGrid">
                    <ProductDiscovery brandId={this.state.brandId} />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let match = window.location.href.match(/#\w+/),
            activeKey = match !== null ? match[0] : "#summary";

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Brand</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.brand_name}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link href="#summary">Summary</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#users">Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#products">Products</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#volume">Volume</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#lookup">URLs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#restrictions">Restrictions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#trends">Trends</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#merchants">Merchants</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#crawlingeffort">Crawling Effort</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#costs" disabled={!this.showCosts}>Costs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#discovery">Product Discovery</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default Index;
