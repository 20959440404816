import React from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    Carousel,
    Table
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ProgressBar from "../../../components/ProgressBar";
import Toast from "../../../services/Toast";
import Emitter from "../../../services/Emitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import OnboardingService from "../../../services/OnboardingService";
import Memory from "../../../services/MemoryService";
import BrandSearch from "../../../components/BrandSearch";
import ProcessingButton from "../../../components/ProcessingButton";
import MyCarousel from "../../../components/MyCarousel";
import MySpinner from "../../../components/MySpinner";
import confirm from "../../../components/ConfirmOnboardingWebsite";
import GsCountriesFilter from "../../../components/GsCountriesFilter";

/**
 * New Job component
 * @component
 * @category Scenes
 * @subcategory Onboarding/Bucketization
 */
class NewJob extends React.Component {
    /**
     * US id is the default country_id
     */
    static DEFAULT_COUNTRY_ID = 233
    constructor(props) {
        super(props);
        this.state = {
            brand: null,
            canSubmit: false,
            canUpload: false,
            page: 0,
            raw: "",
            websites: [],
            processing: -1,
            progress: 0,
            uploading: false,
            comingFromPD: false,
            tableLoading: false,
            countryIdActive: null
        };
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleWebsitesChange = this.handleWebsitesChange.bind(this);
        this.handleGoNext = this.handleGoNext.bind(this);
        this.typeahead = React.createRef();
        this.submit = this.submit.bind(this);
        this.reset = this.reset.bind(this);
        this.handleActionChange = this.handleActionChange.bind(this);
        this.startSubmission = this.startSubmission.bind(this);
        this.performReplacements = this.performReplacements.bind(this);
        this.processNextStep = this.processNextStep.bind(this);
        this.cancelRequests = this.cancelRequests.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleTableBody = this.handleTableBody.bind(this);
        this.memory = {};
    }

    componentDidMount() {
        Emitter.on("ONBOARDING_JOB_NEW", this.reset);
        const data = Memory.Get("PD");
        const countryId = data?.countryId ?? NewJob.DEFAULT_COUNTRY_ID;

        this.setState({page: 1, countryIdActive: countryId})
        this.loadData(countryId)
        Memory.Flush("PD");
    }

    prepareData(data) {
        if (data !== null) {
            let hostnames = [],
                manufacturers = {},
                last = 0;

            data.websites.forEach((website) => {
                hostnames.push(website.hostname);
                manufacturers[website.hostname] = website.manufacturer_id;
                if (website.id > last) { // websites are sorted alphabetically
                    last = website.id;
                }
            })

            this.pd = {
                last,
                manufacturers,
            }

            this.setState({
                countryIdActive: data.countryId,
                comingFromPD: true,
                brand: data.brand,
                raw: hostnames.join("\n"),
            }, this.handleGoNext);
        }
    }

    componentWillUnmount() {
        Emitter.off("ONBOARDING_JOB_NEW");
        Emitter.off("NAVIGATE");
        delete this.memory;
        delete this.pd;
    }

    cancelRequests() {
        this.preventFurtherRequests = true;
    }

    reset() {
        this.typeahead.current.clear();
        this.setState({ page: 0, brand: null, raw: "", websites: [], processing: -1, progress: 0, countryIdActive: null });
    }

    handleBrandChange(text) {

        if (text.length) {
            this.setState({
                brand: text[0],
            });

        } else {
            this.setState({
                brand: null,
                canSubmit: false
            });
        }
    }

    handleWebsitesChange(evt) {
        let canSumit = evt.target.value.trim().length > 0 && this.state.brand !== null;
        this.setState({ raw: evt.target.value, canSubmit: canSumit })
    }

    handleGoNext() {
        delete this.preventFurtherRequests;
        Emitter.on("NAVIGATE", this.cancelRequests);

        let websites = [...new Set(this.state.raw.replace(/[,\n ]/g, "#").replace(/#+/g, '#').split("#"))];
        for (let i = 0; i < websites.length; i++) {
            if (websites[i].length === 0 || !websites[i].match(/\./)) {
                websites.splice(i--, 1)
            }
        }
        if (websites.length) {
            let data = [];
            websites.forEach((website) => {
                data.push({
                    hostname: website,
                    pending: true
                })
            });
            data[0].processing = true;
            this.setState({ page: 1, websites: data, processing: 0, progress: 0, canUpload: false }, this.processNextStep)
        }
    }

    shouldStopProcessing() {
        return typeof this.preventFurtherRequests === 'boolean';
    }

    processNextStep() {
        let current = this.state.processing,
            rows = this.state.websites;

        if (this.shouldStopProcessing()) { // let's stop this cycle when the user navigates away
            return;
        }

        if (current >= rows.length) {
            this.setState({ processing: -1, canUpload: this.canUpload(rows) });
            Toast.ok("Finished");
            return;
        }

        if (typeof this.memory[rows[current].hostname] !== "undefined") {

            rows[current] = this.memory[rows[current].hostname];
            if (current + 1 <= rows.length - 1) {
                rows[current + 1].processing = true;
            }
            this.setState({
                websites: rows,
                processing: current + 1,
                progress: (current + 1) * 100 / rows.length
            }, this.processNextStep);
            return;
        }

        OnboardingService.checkDomain(rows[current].hostname).then((json) => {

            if (this.shouldStopProcessing()) { // let's stop this cycle when the user navigates away
                return;
            }

            if (json === false) {
                Toast.error("Something went wrong while retrieving information about " + rows[current].hostname, 5000);
                this.setState({
                    page: 0,
                    websites: [],
                    processing: -1,
                    progress: 0,
                    uploading: false
                });
                return;
            }

            const variants = typeof json.variants !== 'undefined' ? json.variants : [],
                direct = json.direct ?? false;

            let obj = {
                id: json.id,
                hostname: rows[current].hostname,
                variants,
                status: json.status,
                code: json.code,
                action: json.action,
                direct,
            }

            if (!variants.length) {
                // otherwise this introduces a bug I don't have time to fix right now
                this.memory[rows[current].hostname] = obj;
            }
            if (obj.code === 'variants') {
                rows.splice(current, 1);
                rows.unshift(obj);
            } else {
                rows[current] = obj;
            }

            if (current + 1 <= rows.length - 1) {
                rows[current + 1].processing = true;
            }

            this.setState({
                websites: rows,
                processing: current + 1,
                progress: (current + 1) * 100 / rows.length
            }, this.processNextStep);

        });
    }

    canUpload(list) {
        for (let i = 0; i < list.length; i++) {
            if (typeof list[i].loading !== "undefined") {
                return false;
            }
        }
        return true;
    }

    performReplacements() {
        const list = this.state.websites;
        let unique = list.map((row) => row.variants.length ? null : row.hostname).filter((row) => row !== null);
        for (let i = 0; i < list.length; i++) {
            if (list[i].variants.length) {
                const id = unique.indexOf(list[i].variants[0].name) !== -1
                    ? -1 // this is duplicated, let's just remove it
                    : list[i].variants[0].id;

                this.replaceWebsite(i, id).then(this.performReplacements);
                return;
            }
            unique.push(list[i].hostname);
        }
        this.submit();
    }

    startSubmission() {
        this.setState({
            uploading: true,
        }, this.performReplacements);
    }

    submit() {
        const manufacturers = typeof this.pd !== "undefined" ? this.pd.manufacturers : {},
            finish = () => {
                this.setState({
                    uploading: false,
                });
                Toast.ok(
                    "Done!"
                );
                this.memory = {};
                Memory.Set("REFRESH_ONBOARDING_JOBS", "refresh");
                this.props.history.push("/onboarding/bucketization");
            }

        this.state.websites.forEach((item) => {
            if (typeof manufacturers[item.hostname] !== "undefined") {
                item.manufacturer_id = manufacturers[item.hostname];
            }
        });

        OnboardingService.requestWebsites(this.state.brand.id, this.state.websites).then((result) => {
            if (result === false) {
                Toast.error(
                    "Something went wrong while registering this new job."
                );
                return;
            }
            if (typeof this.pd !== "undefined") {
                OnboardingService.markUnidentifiedAsProcessed(this.pd.last, this.state.countryIdActive).then(finish);
                return;
            }
            finish();
        });
    }


    async handleActionChange(evt) {

        this.setState({ canUpload: false });

        let select = evt.target.closest("select"),
            value = parseInt(evt.target.value),
            index = select.getAttribute("i"),
            rows = this.state.websites,
            row = rows[index],
            shouldConfirm = value === 0,
            confirmCheck = shouldConfirm ? await confirm(row.variants) : value,
            id = 0;

        if (confirmCheck === false) { // have given up creating a new one
            select.selectedIndex = 1;
            this.setState({ canUpload: this.canUpload(rows) });
            return;
        }

        if (confirmCheck !== true) { // picked a variant
            id = parseInt(confirmCheck);
        }

        const unique = rows
            .filter((row) => typeof row.variants === "object" && !row.variants.length)
            .map((row) => row.id);

        if (id && unique.indexOf(id) !== -1) { // already exists in the list
            id = -1;
        }

        this.replaceWebsite(index, id).then(() => {
            this.setState({ canUpload: this.canUpload(rows) });
        });
    }

    replaceWebsite(rowIndex, id) {

        return new Promise((resolve) => {
            let rows = this.state.websites;
            if (id === -1) {
                rows.splice(rowIndex, 1)
            } else if (id === 0) {
                rows[rowIndex].id = 0;
                rows[rowIndex].status = "Doesn't exist";
                rows[rowIndex].action = "Create & Try autogeneration";
                rows[rowIndex].code = 'new';
                rows[rowIndex].variants = [];
            } else {
                for (let variant of rows[rowIndex].variants) {
                    if (variant.id === id) {
                        rows[rowIndex].hostname = variant.name;
                        break;
                    }
                }
                rows[rowIndex].id = id;
                rows[rowIndex].loading = true;

                this.setState({
                    websites: rows,
                }, () => {
                    OnboardingService.checkDomain(rows[rowIndex].hostname).then((json) => {
                        rows[rowIndex] = {
                            id: json.id,
                            hostname: rows[rowIndex].hostname,
                            variants: typeof json.variants !== 'undefined' ? json.variants : [],
                            status: json.status,
                            code: json.code,
                            action: json.action
                        };
                        this.setState({
                            websites: rows,
                        }, resolve);
                    });
                });
                return;
            }

            this.setState({
                websites: rows,
            }, resolve);
        });
    }

    handleCancelButton() {
        if (this.state.comingFromPD) {
            this.props.history.push('/onboarding/bucketization');
            return;
        }
        this.setState({ page: 0 })
    }
    handleGsCountriesChange = (countryId) => {
        this.setState({tableLoading: true})
        this.loadData(countryId)
    }

    /**
     * This is a necessary workaround for the filters to work without having to change the entire structure of the page.
     * The first request happens on the GoogleShoppingPD.js main page, but after making the request and saving
     * the data in memory, a redirect is made and this redirect destroys the state of the main page,
     * so we cannot use it to apply the filters, and we need to load data here in the child page.
     */
    loadData(countryId) {
        OnboardingService.unidentified(countryId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while checking for unidentified websites.");
                this.setState({
                    tableLoading: false,
                    websites: [],
                });
                return;
            }

            if (data.rows.length === 0) {
                this.setState({
                    tableLoading: false,
                    websites: [],
                });
                return;
            }

            this.setState({
                tableLoading: false
            });

            this.prepareData({
                countryId: countryId,
                brand: data.brand,
                websites: data.rows,
            })
        });
    }

    /**
     * This is also a workaround to add a load to the table when a filter is applied.
     * This is necessary because we can't use the main page.
     * @returns {*|JSX.Element}
     */
    handleTableBody() {
        if (this.state.tableLoading) {
            return (
                <tr>
                    <td></td>
                    <td colSpan="3"><MySpinner /></td>
                    <td></td>
                </tr>
            )
        }

        if (this.state.websites.length === 0) {
            return (
                <tr>
                    <td colSpan="3">Empty data set</td>
                </tr>
            )
        }

        return this.state.websites.map((row, i) => {
            let status, action;
            if (typeof row.id === "undefined") {
                status = typeof row.processing !== "undefined" ? (
                    <MySpinner />
                ) : "In-queue";
                action = "";
            } else {
                status = typeof row.loading !== "undefined" ? "Loading" : (row.code !== 'error' ? row.status : (<font color="red">{row.status}</font>));
                action = typeof row.loading !== "undefined" ? (
                    <MySpinner />
                ) : (row.action === 'Skip' ? (
                    <i>Skip</i>
                ) : (row.code === 'variants' ? (
                    <Form.Select
                        key={`s${row.hostname}`}
                        i={i}
                        onChange={this.handleActionChange}
                        defaultValue={row.variants[0].id}
                        disabled={!this.state.canUpload}
                    >
                        <option key={`s${row.hostname}-autogeneration`} value={0}>{`Create & Try autogeneration`}</option>
                        {row.variants.map((m, j) => {
                            let extra = [];
                            if (m.crawls > 0) {
                                extra.push(`Recently Crawled Offers: ${m.crawls}`);
                            }
                            if (m.urls > 0) {
                                extra.push(`Mapped URLs: ${m.urls}`);
                            }
                            if (m.parser) {
                                extra.push(`Has Crawler`);
                            }
                            extra = extra.length ? ` (${extra.join('; ')})` : '';
                            return <option key={`s${row.hostname}-replace-${j}`} value={m.id}>Replace by {m.name} {extra}</option>
                        })}
                        <option key={`s${row.hostname}-cancel`} value={-1}>{`Cancel/Remove website`}</option>
                    </Form.Select>
                ) : row.action))

            }
            return (
                <tr key={`r${i}`} className={row.code === 'variants' ? 'highlighted' : null}>
                    <td>{row.hostname}</td>
                    <td>{status}</td>
                    <td>{action}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <Container className="pt-3 dd">
                <MyCarousel activeIndex={this.state.page}>
                    <Carousel.Item className="standardGrid">
                        <Breadcrumb className="dd">
                            <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/onboarding/bucketization">
                                    Bucketization
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>New</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>

                            <Col
                                sm={2}
                                className="d-flex align-items-center justify-content-end text-center"
                            >
                                Brand
                            </Col>
                            <Col sm={7}>
                                <BrandSearch ref={this.typeahead} onChange={this.handleBrandChange} />

                            </Col>
                        </Row>
                        <Row>
                            <Col
                                sm={2}
                                className="d-flex align-items-center justify-content-end text-center"
                            >
                                Websites
                            </Col>
                            <Col sm={7}>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    value={this.state.raw}
                                    onChange={this.handleWebsitesChange}
                                    style={{
                                        height: "350px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                className="d-flex align-items-center justify-content-center text-center"
                            >
                                <Button variant="outline-primary" onClick={this.handleGoNext}
                                    disabled={!this.state.canSubmit}
                                >Next
                                    <FontAwesomeIcon icon={faArrowRight} style={{
                                        marginLeft: "10px"
                                    }} />
                                </Button>
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item className="standardGrid">
                        <Breadcrumb className="dd">
                            <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                            {this.state.processing === -1 ?
                                <React.Fragment>
                                    <Breadcrumb.Item>
                                        <Link
                                            to="/onboarding/bucketization">
                                            Bucketization
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => this.setState({ page: 0 }) }>
                                        New
                                    </Breadcrumb.Item>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Breadcrumb.Item>Bucketization
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>New</Breadcrumb.Item>
                                </React.Fragment>}
                            <Breadcrumb.Item active>{this.state.brand !== null ? this.state.brand.name : 'Actions'}</Breadcrumb.Item>
                        </Breadcrumb>
                        {this.state.processing !== -1 && (
                            <ProgressBar
                                bgcolor={"#1f4503"}
                                completed={this.state.progress}
                            />
                        )}
                        {this.state.countryIdActive !== null &&
                            <GsCountriesFilter countryId={this.state.countryIdActive}
                                               onChange={this.handleGsCountriesChange}/>
                        }
                        <Table bordered hover size="sm" className="myTable">
                            <thead>
                                <tr className="title">
                                    <th>Website</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.handleTableBody()}
                            </tbody>
                        </Table>
                        <Row>
                            <Col
                                className="d-flex align-items-center justify-content-center text-center"
                            >
                                <Button
                                    variant="outline-secondary"
                                    disabled={this.state.uploading}
                                    onClick={this.handleCancelButton}>
                                    <FontAwesomeIcon
                                        icon={this.state.comingFromPD ? faTimes : faArrowLeft} style={{
                                            marginRight: "10px"
                                        }} />{this.state.comingFromPD ? "Cancel" : "Back"}
                                </Button>

                                <ProcessingButton
                                    variant="outline-primary"
                                    processing={this.state.uploading}
                                    processingLabel="Submitting ..."
                                    label="Submit"
                                    icon={faUpload}
                                    onClick={this.startSubmission}
                                    disabled={!this.state.canUpload}
                                />
                            </Col>
                        </Row>
                    </Carousel.Item>
                </MyCarousel>

            </Container >
        );
    }
}

export default withRouter(NewJob);
